import {Fragment, useContext, useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Delete, Download, Edit} from "@mui/icons-material";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getAllClaims} from "../../api/claims";
import {getAllRegions} from "../../api/regions";
import {getAllIndustries} from "../../api/industries";
import ChipMultiSelect from "../custom/ChipMultiSelect";
import {download, generateCsv, mkConfig} from "export-to-csv";
import {certificationsQueryKey} from "../../constants";
import {
    createCertification,
    deleteCertification,
    getAllCertifications,
    updateCertification
} from "../../api/certifications";
import LoginContext from "../../login-context/LoginContext";

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const Certifications = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editNewDialogOpen, setEditNewDialogOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState({name: "", id: ""});
    const [itemToDelete, setItemToDelete] = useState({name: "", id: ""});
    const [isNewItem, setIsNewItem] = useState(false);
    const [claims, setClaims] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [tokenResponse, setTokenResponse] = useContext(LoginContext)
    const queryClient = useQueryClient();

    useEffect(() => {
        getAllClaims(tokenResponse?.access_token, () => setTokenResponse({}))().then(data => setClaims(data));
        getAllRegions().then(data => setRegions(data.map(i => {
            return {id: i.id, name: i.description}
        })));
        getAllIndustries().then(data => setIndustries(data));
    }, [setTokenResponse, tokenResponse?.access_token]);

    const openDeleteConfirmModal = (item) => {
        setItemToDelete(item.original);
        setDeleteDialogOpen(true);
    }

    const handleExport = (data) => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }

    const onDeleteClaim = (id) => {
        mutateDeleteCertification(id);
        setDeleteDialogOpen(false);
    }

    const onCreateCertificationDialogOpen = () => {
        setIsNewItem(true);
        setItemToEdit({});
        setEditNewDialogOpen(true);
    }

    const onEditCertificationDialogOpen = (item) => {
        setIsNewItem(false);
        setItemToEdit(item.original);
        setEditNewDialogOpen(true);
    }

    const onSubmitButtonClick = async () => {
        if (isNewItem) {
            await mutateCreateCertification(itemToEdit);
        } else {
            await mutateUpdateCertification(itemToEdit);
        }
        setEditNewDialogOpen(false);
    }

    const {
        isLoading: certificationsLoading,
        data: certifications = []
    } = useQuery(certificationsQueryKey, getAllCertifications, {refetchOnWindowFocus: false});

    const {
        mutate: mutateCreateCertification,
        isLoading: isCreateCertificationLoading
    } = useMutation({
        mutationFn: createCertification,
        onSettled: () => queryClient.invalidateQueries(certificationsQueryKey)
    })

    const {
        mutate: mutateDeleteCertification,
        isLoading: isDeleteCertificationLoading
    } = useMutation({
        mutationFn: deleteCertification,
        onSettled: () => queryClient.invalidateQueries(certificationsQueryKey)
    });

    const {
        mutate: mutateUpdateCertification,
        isLoading: isUpdateCertificationLoading
    } = useMutation({
        mutationFn: updateCertification,
        onSettled: () => queryClient.invalidateQueries(certificationsQueryKey)
    });


    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            type: 'number',
            enableEditing: false,
            size: 50
        },
        {
            accessorKey: 'name',
            header: 'Certification Name/Organization',
            type: 'string',
            enableEditing: true,
            size: 350
        },
        {
            accessorFn: original => original.claims?.map(c => claims.find(claim => claim.id === c)?.name).join(", "),
            header: 'Green Claims',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.claims?.map((claim, index) => (
                            <Chip key={index} label={(claims || []).find(c => c.id === claim)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
            ,

        },
        {
            accessorFn: original => original.industries?.map(i => industries.find(ind => ind.id === i)?.name).join(", "),
            header: 'Product Category',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.industries?.map((industry, index) => (
                            <Chip key={index} label={(industries || []).find(i => i.id === industry)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
        },
        {
            accessorFn: original => original.regions?.map(r => regions.find(reg => reg.id === r)?.name).join(", "),
            header: 'Geography',
            type: 'string',
            size: 400,
            Cell: ({renderedCellValue, row}) =>
                (
                    <Box>
                        {row.original.regions?.map((region, index) => (
                            <Chip key={index} label={(regions || []).find(i => i.id === region)?.name}
                                  color="primary" sx={{m: 0.5}}/>
                        ))}
                    </Box>
                )
        },
        {
            accessorKey: 'websiteUrl',
            header: 'Website',
            type: 'string',
            enableEditing: true
        },
        {
            accessorKey: 'isAmazon',
            header: 'Is Amazon',
            enableEditing: true,
            Cell: ({renderedCellValue, row}) => (
                <Checkbox checked={row.original.isAmazon}/>
            )
        },
        {
            accessorKey: 'type',
            header: 'Type',
            enableEditing: true,
        },
        {
            accessorKey: 'level',
            header: 'Certification Level',
            enableEditing: true,
        }
    ];

    return (
        <Fragment>
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete item "{itemToDelete.name}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => onDeleteClaim(itemToDelete.id)}>Yes</Button>
                    <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editNewDialogOpen} fullWidth maxWidth={"md"}>
                <DialogTitle>
                    {isNewItem ? "Add New Certification" : "Edit Certification"}
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} flexDirection={"column"}>
                        <TextField label={"Certification Name/Organization"} sx={{mt: 2}}
                                   onChange={e => setItemToEdit({...itemToEdit, name: e.target.value})}
                                   value={itemToEdit.name}/>
                        <ChipMultiSelect options={claims} selectedOptions={itemToEdit.claims || []}
                                         label={"Green Claims"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, claims: v})}/>
                        <ChipMultiSelect options={industries} selectedOptions={itemToEdit.industries || []}
                                         label={"Product Categories"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, industries: v})}/>
                        <ChipMultiSelect options={regions}
                                         selectedOptions={itemToEdit.regions || []}
                                         label={"Geography"} sx={{mt: 2}}
                                         setSelectedOptions={v => setItemToEdit({...itemToEdit, regions: v})}/>
                        <TextField label={"Website"} sx={{mt: 2}} value={itemToEdit.websiteUrl}
                                   onChange={e => setItemToEdit({...itemToEdit, websiteUrl: e.target.value})}/>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={itemToEdit.isAmazon}
                                onChange={(e, c) => setItemToEdit({...itemToEdit, isAmazon: c})}/>}
                                              label={"Is Amazon Certification?"} sx={{mt: 2}}/>
                        </FormGroup>
                        <FormControl fullWidth sx={{mt: 2}}>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={itemToEdit.type}
                                label="Age"
                                onChange={(e) => setItemToEdit({...itemToEdit, type: e.target.value})}
                            >
                                <MenuItem value={null}>N/A</MenuItem>
                                <MenuItem value={'Certification Organization'}>Certification Organization</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{mt: 2}}>
                            <InputLabel id="demo-simple-select-label">Certification Level</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={itemToEdit.level}
                                label="Age"
                                onChange={(e) => setItemToEdit({...itemToEdit, level: e.target.value})}
                            >
                                <MenuItem value={null}>N/A</MenuItem>
                                <MenuItem value={'Product'}>Product</MenuItem>
                                <MenuItem value={'Product & Service'}>Product & Service</MenuItem>
                                <MenuItem value={'Manufacturer'}>Manufacturer</MenuItem>
                                <MenuItem value={'Operations'}>Operations</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setEditNewDialogOpen(false)}>Cancel</Button>
                    <Button variant="contained" type={"submit"} onClick={onSubmitButtonClick}>Save</Button>
                </DialogActions>
            </Dialog>
            <Typography variant={"h4"}>Certifications</Typography>
            <MaterialReactTable
                columns={columns}
                data={certifications}
                enableEditing={true}
                state={{
                    isLoading: certificationsLoading,
                    isSaving: isCreateCertificationLoading || isDeleteCertificationLoading || isUpdateCertificationLoading
                }}
                editDisplayMode={"row"} createDisplayMode={"row"}
                enablePagination={true}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilterModes={true}
                enableColumnPinning={true}
                enableColumnResizing={true}
                initialState={{
                    showColumnFilters: false,
                    columnPinning: {left: ['mrt-row-actions', 'name']}
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        size: 180, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
                        grow: false,
                    },
                }}
                renderRowActions={({row, table}) => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => onEditCertificationDialogOpen(row)}>
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <Button
                                variant="contained"
                                onClick={onCreateCertificationDialogOpen}
                            >
                                Add Certification
                            </Button>
                            <Button variant={"contained"} sx={{ml: 2}} onClick={_ => handleExport(certifications)}>
                                <Download/>
                                Download CSV
                            </Button>
                        </Box>
                    );

                }}

            />
        </Fragment>
    )
}

export default Certifications;